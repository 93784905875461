import {Link, Outlet} from "react-router-dom";
import React from "react";
import {useHasRole} from "../../hooks/useHasRole";
import {OVERVIEW_ROUTE} from "../../Routes";

type RoleProtectedProps = {
    role: string;
}

export const RoleProtected = ({ role }: RoleProtectedProps) => {
   const { loading, hasRole } = useHasRole(role);

    if (loading)
        return (
            <div className="container min-vh-100 align-items-center">
                <div
                    className="position-absolute top-50 start-50 spinner-border text-primary"
                    role="status"
                >
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );

    // check if role is in roles
    if (!hasRole) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 offset-lg-2 col-lg-8">
                        <div className="h1 text-center">403 Forbidden</div>
                        <div className="h6 text-center">
                            Ooops, you are not allowed to see this page. Return to{" "}
                            <Link to={OVERVIEW_ROUTE}>home</Link>
                        </div>
                    </div>
                </div>
            </div>
    );
    }

    return <Outlet />
}