import React, {useEffect, useMemo, useState} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Profiles, Spectra } from "../../typings";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { onSnapshot, doc } from "firebase/firestore";
import { SpectrumPlot } from "../../components/SpectrumPlot";
import { httpsCallable } from "firebase/functions";
import { analytics, auth, db, functions } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { getSpectraWarning } from "../../utils/get-spectra-warning";
import { logEvent } from "firebase/analytics";
import { SpectraRowHeader } from "../../components/SpectraRowHeader";
import { isSpectraQualityOrder } from "../../utils/is-spectra-quality-order";
import { SPECTRA_QUALITY_SPECTRA_DETAILS_ROUTE } from "../../Routes";
import classNames from "classnames";
import {getMatches} from "../../utils/get-matches";

export const IdentificationSpectraDetails: React.FC = () => {
  const [spectra, setSpectra] = useState<Spectra | undefined>(undefined);
  const [isSpectraLoadig, setIsSpectraLoading] = useState<boolean>(true);
  const [isProfilesLoading, setIsProfilesLoading] = useState<boolean>(false);
  const [hasProfilesError, setHasProfilesError] = useState<boolean>(false);
  const [selectedProfile, setSelectedProfile] = useState<string>();
  const [profiles, setProfiles] = useState<Profiles>();
  const [user, loading] = useAuthState(auth);
  const { id } = useParams();

  const navigate = useNavigate();

  const profileUsed = useMemo(() => profiles ? profiles[selectedProfile || ""] : [], [profiles, selectedProfile]);
  const matchData = useMemo(() => getMatches(spectra?.masses || [], 800, profileUsed), [spectra?.masses, profileUsed]);

  // redirect to spectra quality detail
  useEffect(() => {
    if (loading) return;
    if (!user) return;
    if (!spectra) return;

    if (isSpectraQualityOrder(spectra.database))
      navigate(
        generatePath(SPECTRA_QUALITY_SPECTRA_DETAILS_ROUTE, { id: spectra.id })
      );
  });

  useEffect(() => {
    if (loading) return;
    if (!user) return;
    if (id === undefined) return;

    const unsubscribe = onSnapshot(doc(db, "spectras", id), (doc) => {
      const data: Spectra = doc.data() as Spectra;
      setSpectra({ ...data, id: doc.id });
      setIsSpectraLoading(false);
    });
    return () => unsubscribe();
  }, [user, loading, id]);

  useEffect(() => {
    if (loading) return;
    if (!user) return;
    if (id === undefined) return;
    if (spectra === undefined) return;
    if (!spectra.matchedProfiles) return;

    const getProfiles = httpsCallable(functions, "getProfiles");

    setIsProfilesLoading(true);

    // always load the coli profile "Escherichia/coli/rp1118-1062"
    getProfiles({ profiles: spectra.matchedProfiles })
      .then(({ data }) => {
        const profilesData = data as Profiles;

        setProfiles(profilesData);
        // set the first profile as selected
        if(Object.keys(profilesData).length > 0) setSelectedProfile(Object.keys(profilesData)[0]);
        setIsProfilesLoading(false);
      })
      .catch((err) => {
        console.error("Failed to load profiles");
        setIsProfilesLoading(false);
        setHasProfilesError(true);
      });
  }, [user, loading, id, spectra]);

  if (isSpectraLoadig) {
    return (
      <div className="container min-vh-100 align-items-center">
        <div
          className="position-absolute top-50 start-50 spinner-border text-primary"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const handleProfileSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    logEvent(analytics, "user_selected_spectrum-plot_profile");
    setSelectedProfile(e.target.value);
  };

  if (spectra === undefined) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> Spectra
            with not found.
          </div>
        </div>
      </div>
    );
  }

  const getDefaultValue = () => {
    if (isProfilesLoading) {
      return (
        <option value="" key="default">
          Loading...
        </option>
      );
    }
    if (hasProfilesError) {
      return (
        <option value="" key="default">
          Failed to load profiles!
        </option>
      );
    }

    return <option value="" key="default"></option>;
  };

  const {
    file,
    sample,
    result,
    nonQualifiedResult,
    masses,
    error,
    database,
    databaseUsed,
    score,
    nonQualifiedScore,
  } = spectra;

  const warning = getSpectraWarning(databaseUsed || database);
  const hasWarning = !!warning;

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <SpectraRowHeader />
        </div>
        <div className="col-12">
          <div className="detail-results__wrapper bg-white">
            <div className="row gy-20">
              <div className="col-12 col-sm-6 col-lg-2">
                <span className="text-break">
                  <span className="d-block d-lg-none fw-bold">Filename:</span>
                  {file}
                </span>
              </div>
              <div className="col-12 col-sm-6 col-lg-2">
                <span className="text-break">
                  <span className="d-block d-lg-none fw-bold">
                    Sample Name:
                  </span>
                  {sample}
                </span>
              </div>
              <div className="col-12 col-sm-6 col-lg-1">
                <div>
                  <span className="d-block d-lg-none fw-bold">Datacount:</span>
                  <div className="fw-bold">{masses && masses.length}</div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-1">
                <div>
                  <span className="d-block d-lg-none fw-bold">
                    Qualified score:
                  </span>
                  <div className="fw-bold">
                    {score && score > 0 ? `${score}%` : "---"}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <div>
                  <span className="d-block d-lg-none fw-bold">
                    Qualified result:
                  </span>
                  {result ? (
                    result.map((profile, index) => (
                      <div className="result result-qualified" key={index}>
                        <span className="ball" />
                        {profile}
                      </div>
                    ))
                  ) : (
                    <div className="result result-qualified">---</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-1">
                <div className="row gy-20">
                  <div className="col-12">
                    <div className="d-lg-none fw-bold">
                      Non-Qualified score:
                    </div>
                    <div className="fw-bold">
                      {nonQualifiedScore && nonQualifiedScore > 0
                        ? `${nonQualifiedScore}%`
                        : "---"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-2">
                <div className="row gy-20">
                  <div className="col-12">
                    <div className="d-lg-none">Non-Qualified result:</div>
                    {nonQualifiedResult ? (
                      nonQualifiedResult.map((profile, index) => (
                        <div
                          className={classNames({
                            result: true,
                            "result-not-qualified":
                              nonQualifiedScore && nonQualifiedScore >= 70,
                            "result-none":
                              nonQualifiedScore && nonQualifiedScore < 70,
                          })}
                          key={index}
                        >
                          <span className="ball"></span>
                          {profile}
                        </div>
                      ))
                    ) : (
                      <div className="result result-not-qualified">---</div>
                    )}
                  </div>
                </div>
              </div>

              <hr />

              {/* spectra error */}
              {error && (
                <div className="col-12 col-xl-10 offset-xl-1 mt-30">
                  <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />{" "}
                  Something went wrong while processing this spectrum.
                </div>
              )}

              {/* spectra warning */}
              {hasWarning && (
                <div className="col-12 col-xl-10 offset-xl-1 mt-30">
                  <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />{" "}
                  {warning}
                </div>
              )}

              <div className="col-12 col-xl-10 offset-xl-1 mt-30">
                <div className="mb-60">
                  <label htmlFor="matchedProfile" className="fw-bolder mb-20">
                    Compare with a matched profile
                  </label>

                  <select
                    className="form-select bg-light"
                    id="matchedProfile"
                    name="matchedProfile"
                    aria-label="Select matched profile"
                    value={selectedProfile}
                    onChange={handleProfileSelect}
                    disabled={isProfilesLoading || hasProfilesError}
                  >
                    {getDefaultValue()}
                    {profiles &&
                      Object.keys(profiles).map((profile, index) => (
                        <option value={profile} key={index}>
                          {profile}
                        </option>
                      ))}
                  </select>
                </div>

                <SpectrumPlot
                  masses={masses || []}
                  matchedProfile={matchData.map(({mw, subunit, mz, mz_error}) => ({
                    mw,
                    subunit,
                    matched: !!mz,
                    mz_error
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
