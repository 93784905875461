import { useEffect } from "react";

const usePreventUnload = (shouldPreventUnload: boolean, message: string = "Are you sure you want to leave? Changes you made may not be saved.") => {
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (!shouldPreventUnload) return;

            event.preventDefault(); // Required for some browsers
            event.returnValue = message; // Chrome and modern browsers
            return message; // For older browsers
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [shouldPreventUnload, message]);
};

export default usePreventUnload;