import {useAuthState} from "react-firebase-hooks/auth";
import {auth, db} from "../firebase";
import {useEffect, useState} from "react";
import {doc, onSnapshot} from "firebase/firestore";

export const useHasRole = (role: string): {loading: boolean, hasRole: boolean} => {
    const [user, loading] = useAuthState(auth);
    const [isRolesLoading, setIsRolesLoading] = useState<boolean>(true);
    const [roles, setRoles] = useState<string[]>([]);

    useEffect(() => {
        if (loading) return;
        if (!user?.uid) return;

        const unsubscribe = onSnapshot(doc(db, "users", user.uid), (doc) => {
            const data = doc.data();
            if (data?.roles) {
                setRoles(data.roles);
            }
            setIsRolesLoading(false);
        });

        return () => unsubscribe();
    }, [user, loading]);


    return {
        loading: isRolesLoading,
        hasRole: roles.includes(role)
    };
}