

export const ppmToHexColor = (ppm: number): string => {
    const min = 400;
    const max = 1200;

    // Clamp the number to the range [min, max]
    ppm = Math.max(min, Math.min(ppm, max));

    const interpolate = (start: number, end: number, factor: number) => {
        return Math.round(start + (end - start) * factor);
    };

    const factor = (ppm - min) / (max - min); // Normalize the number to range [0, 1]
    const r = interpolate(212, 220, factor);
    const g = interpolate(223, 53, factor);
    const b = interpolate(0, 70, factor);

    return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;
}