import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Overview } from "./pages/Overview";
import { Login } from "./pages/Login";
import { NotFound } from "./pages/NotFound";
import { OrdersList } from "./pages/OrdersList";
import { Authenticated } from "./theme/layout/Authenticated";
import {
  ACCOUNT_ROUTE, FUNGI_SSP_UPLOAD,
  IDENTIFICATION_ORDER,
  IDENTIFICATION_SPECTRA_DETAILS_ROUTE,
  IDENTIFICATION_UPLOAD,
  LOGIN_ROUTE, MACO_MATCHES_DETAILS_ROUTE,
  MACO_MATCHES_ROUTE,
  MOSQUITO_VECTORS_UPLOAD,
  ORDERS_ROUTE,
  OVERVIEW_ROUTE,
  RESET_PASSWORD_ACTION_ROUTE,
  RESET_PASSWORD_ROUTE,
  SPECTRA_QUALITY_ORDER,
  SPECTRA_QUALITY_SPECTRA_DETAILS_ROUTE,
  SPECTRA_QUALITY_UPLOAD,
} from "./Routes";
import { ResetPassword } from "./pages/ResetPassword";
import { IdentificationSpectraDetails } from "./pages/identification/IdentificationSpectraDetails";
import { ResetPasswordAction } from "./pages/actions/ResetPasswordAction";
import { IdentificationUpload } from "./pages/identification/IdentificationUpload";
import { SpectraQualityUpload } from "./pages/spectra-quality/SpectraQualityUpload";
import { IdentificationOrder } from "./pages/identification/IdentificationOrder";
import { SpectraQualityOrder } from "./pages/spectra-quality/SpectraQualityOrder";
import { SpectraQualitySpectraDetails } from "./pages/spectra-quality/SpectraQualitySpectraDetails";

// import order result css
import "./Order.scss";
import { MosquitoVectorsUpload } from "./pages/mosquito-egg/MosquitoVectorsUpload";
import { Account } from "./pages/account/Account";
import { MacoMatches } from "./pages/maco/MacoMatches";
import {MacoMatchDetail} from "./pages/maco/MacoMatchDetail";
import {RoleProtected} from "./components/auth/RoleProtected";
import {FungiUpload} from "./pages/fungi/FungiUpload";

function App() {
  useEffect(() => {
    if (!window) return;

    // disable firebase default urls
    if (
      window.location.hostname === "mabriteccentral.web.app" ||
      window.location.hostname === "mabriteccentral.firebaseapp.com"
    ) {
      window.location.href = "https://app.mabriteccentral.com/";
    }
  });

  return (
    <div className="App">
      <Routes>
        <Route element={<Authenticated />}>
          <Route path={OVERVIEW_ROUTE} element={<Overview />} />
          <Route path={ORDERS_ROUTE} element={<OrdersList />} />
          <Route path={ACCOUNT_ROUTE} element={<Account />} />

          {/* Identification */}
          <Route
            path={IDENTIFICATION_UPLOAD}
            element={<IdentificationUpload />}
          />
          <Route
            path={IDENTIFICATION_ORDER}
            element={<IdentificationOrder />}
          />
          <Route
            path={IDENTIFICATION_SPECTRA_DETAILS_ROUTE}
            element={<IdentificationSpectraDetails />}
          />

          {/* Spectra Quality */}
          <Route
            path={SPECTRA_QUALITY_UPLOAD}
            element={<SpectraQualityUpload />}
          />
          <Route
            path={SPECTRA_QUALITY_ORDER}
            element={<SpectraQualityOrder />}
          />
          <Route
            path={SPECTRA_QUALITY_SPECTRA_DETAILS_ROUTE}
            element={<SpectraQualitySpectraDetails />}
          />

          {/* Mosquito egg*/}
          <Route
            path={MOSQUITO_VECTORS_UPLOAD}
            element={<MosquitoVectorsUpload />}
          />

          {/* Fungi SSP*/}

          <Route element={<RoleProtected role="fungi" />}>
            <Route path={FUNGI_SSP_UPLOAD} element={<FungiUpload />} />
          </Route>

          <Route element={<RoleProtected role="compare" />}>
            {/* Maco Manual Compare*/}
            <Route path={MACO_MATCHES_ROUTE} element={<MacoMatches />} />
            <Route path={MACO_MATCHES_DETAILS_ROUTE} element={<MacoMatchDetail />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Route>


        {/* AUTHENTICATION */}
        <Route path={LOGIN_ROUTE} element={<Login />} />
        <Route path={RESET_PASSWORD_ROUTE} element={<ResetPassword />} />
        {/* CUSTOM ACTION FORMS FIREBASE*/}
        <Route
          path={RESET_PASSWORD_ACTION_ROUTE}
          element={<ResetPasswordAction />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
