import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import {
  collection,
  query,
  orderBy,
  where,
  limitToLast,
  limit,
  getDocs,
  startAfter,
  endBefore,
  getCountFromServer,
  QueryDocumentSnapshot,
  DocumentData,
} from "firebase/firestore";
import classNames from "classnames";
import { generatePath, Link } from "react-router-dom";
import { motion } from "motion/react";
import { Order } from "../typings";
import { IDENTIFICATION_ORDER, SPECTRA_QUALITY_ORDER } from "../Routes";
import { isSpectraQualityOrder } from "../utils/is-spectra-quality-order";
import "./OrderList.scss";
import { getOrderType } from "../utils/get-order-type";

const LIMIT = 20;

export const OrdersList: React.FC = () => {
  const [lastDocument, setLastDocument] =
    useState<QueryDocumentSnapshot<DocumentData>>();
  const [firstDocument, setFirstDocument] =
    useState<QueryDocumentSnapshot<DocumentData>>();
  const [orders, setOrders] = useState<Order[]>([]);
  const [user, loading] = useAuthState(auth);
  const [page, setPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);

  useEffect(() => {
    if (loading) return;
    if (!user) return;

    const collectionRef = collection(db, "orders");
    const q = query(
      collectionRef,
      where("userId", "==", user.uid),
      orderBy("uploadedAt", "desc"),
      limit(LIMIT)
    );

    getDocs(q).then((querySnapshot) => {
      const newOrders: Order[] = [];
      querySnapshot.forEach((doc) => {
        // update orders
        const data: Order = doc.data() as Order;
        newOrders.push(data);
      });

      setOrders(newOrders);
      setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setFirstDocument(querySnapshot.docs[0]);
    });
  }, [user, loading]);

  useEffect(() => {
    if (loading) return;
    if (!user) return;

    const collectionRef = collection(db, "orders");
    const q = query(collectionRef, where("userId", "==", user.uid));

    getCountFromServer(q).then((querySnapshot) => {
      const count = querySnapshot.data().count;
      setMaxPage(count < LIMIT ? 1 : Math.ceil(count / LIMIT));
    });
  }, [user, loading]);

  const fetchNextPage = () => {
    if (loading) return;
    if (!user) return;
    const collectionRef = collection(db, "orders");
    const q = query(
      collectionRef,
      where("userId", "==", user.uid),
      orderBy("uploadedAt", "desc"),
      startAfter(lastDocument),
      limit(LIMIT)
    );

    getDocs(q).then((querySnapshot) => {
      const newOrders: Order[] = [];
      querySnapshot.forEach((doc) => {
        // update orders
        const data: Order = doc.data() as Order;
        newOrders.push(data);
      });

      setOrders(newOrders);
      setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setFirstDocument(querySnapshot.docs[0]);
      setPage(page + 1);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [orders]);

  const fetchPreviousPage = () => {
    if (loading) return;
    if (!user) return;
    const collectionRef = collection(db, "orders");
    const q = query(
      collectionRef,
      where("userId", "==", user.uid),
      orderBy("uploadedAt", "desc"),
      endBefore(firstDocument),
      limitToLast(LIMIT)
    );

    getDocs(q).then((querySnapshot) => {
      const newOrders: Order[] = [];
      querySnapshot.forEach((doc) => {
        // update orders
        const data: Order = doc.data() as Order;
        newOrders.push(data);
      });

      setOrders(newOrders);
      setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setFirstDocument(querySnapshot.docs[0]);
    });
    setPage(page - 1);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="d-none d-lg-block order-list-results__header bg-primary py-20 px-30">
            <div className="row">
              <div className="col-9">
                <div className="row">
                  <div className="col-3">
                    <strong className="text-black">Date</strong>
                  </div>
                  <div className="col-1">
                    <strong className="text-black">Files</strong>
                  </div>
                  <div className="col-1">
                    <strong className="text-black">Spectra</strong>
                  </div>
                  <div className="col-3">
                    <strong className="text-black">Customer reference</strong>
                  </div>
                  <div className="col-2">
                    <strong className="text-black">Type</strong>
                  </div>
                  <div className="col-2">
                    <strong className="text-black">Status</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {orders.length > 0 &&
          orders.map((order, i) => {
            const {
              uploadedAt,
              orderId,
              files,
              status,
              spectraCount,
              database,
              customerReference,
            } = order;
            const routePath = isSpectraQualityOrder(database)
              ? SPECTRA_QUALITY_ORDER
              : IDENTIFICATION_ORDER;

            return (
              <motion.div
                  initial={{
                    opacity: 0,
                    y: 25
                  }}
                  animate={{
                    opacity: 1,
                    y: 0
                  }}
                  transition={{ delay: i * 0.08}}
                  className="col-12" key={i}>
                <div className="order-list-results__wrapper bg-white">
                  <div className="row gy-20">
                    <div className="col-12 col-lg-9">
                      <div className="d-flex flex-column justify-content-center h-100">
                        <div className="row">
                          <div className="col-12 col-sm-6 col-lg-3">
                            <span>
                              <span className="d-block d-lg-none fw-bold">
                                Date:
                              </span>
                              {new Date(
                                uploadedAt.seconds * 1000
                              ).toLocaleString()}
                            </span>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-1">
                            <span>
                              <span className="d-block d-lg-none fw-bold">
                                Files:
                              </span>
                              {files.length}
                            </span>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-1">
                            <span>
                              <span className="d-block d-lg-none fw-bold">
                                Spectra:
                              </span>
                              {spectraCount}
                            </span>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-3">
                            <span className="text-break">
                              <span className="d-block d-lg-none fw-bold text-break">
                                Customer reference:
                              </span>
                              <span className="text-break">
                                {customerReference}
                              </span>
                            </span>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-2">
                            <span className="d-block d-lg-none fw-bold">
                              Type:
                            </span>
                            <span className="text-break">
                              {getOrderType(order)}
                            </span>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-2">
                            <span className="d-block d-lg-none fw-bold">
                              Status:
                            </span>
                            {status === "DONE" && (
                              <div className="result result-done">
                                <span className="ml-10">Completed </span>
                              </div>
                            )}
                            {status === "RUNNING" && (
                              <div className="result result-progress">
                                <span className="ml-10">In Progress </span>
                              </div>
                            )}
                            {status === "FAILED" && (
                              <div className="result result-error">
                                <span className="ml-10">Failed </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-3">
                      <div className="d-flex align-items-center justify-content-lg-end">
                        <Link
                          to={generatePath(routePath, { orderId: orderId })}
                          className="btn btn-primary"
                        >
                          more details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            );
          })}

        {orders.length > 0 && (
          <div className="col-12">
            <nav
              aria-label="Page navigation example"
              className="d-flex justify-content-center"
            >
              <ul className="pagination">
                <li
                  className={classNames("page-item", { disabled: page === 1 })}
                >
                  <button
                    className="page-link"
                    onClick={() => fetchPreviousPage()}
                    aria-label="Previous"
                    disabled={page === 1}
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </button>
                </li>
                <li className="page-item">
                  <div className="page-link">{page}</div>
                </li>
                <li
                  className={classNames("page-item", {
                    disabled: page === maxPage,
                  })}
                >
                  <button
                    className="page-link"
                    onClick={() => fetchNextPage()}
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};
