import {MacoMatchDetailEntry} from "../typings";


export const getMatches = (masses: { mz: number; int: number }[], ppm: number, profile: { mw: number, subunit: string, int?: number}[]): MacoMatchDetailEntry[] => {
    if(!profile) return [];
    if(profile && profile.length === 0) return [];

    return profile.map(({ mw, subunit, int }) => {
        // find mz in masses that is in range mw with ppm
        const match = masses.filter(({ mz }) => {
            return mz >= mw - mw * ppm / 1000000 && mz <= mw + mw * ppm / 1000000;
        })

        // if no match found return object with null mz
        if(match.length === 0) return { mw, subunit, subunitIntensity: int };

        // get object with the greatest intensity from match
        const gtMatch = match.reduce((prev, current) => (prev.int > current.int ? prev : current));
        return { mw, subunit, subunitIntensity: int, mz: gtMatch.mz, intensity: gtMatch.int, mz_error: Math.abs(((gtMatch.mz - mw) / mw) * 1000000) };
    })
}