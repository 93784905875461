import React, { FormEvent, useState } from "react";
import { analytics, createSpectraQualityOrder } from "../../firebase";
import { generatePath, useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { SPECTRA_QUALITY_ORDER } from "../../Routes";
import accept from "attr-accept";
import { MachineType } from "../../typings";
import { MachineTypeSelector } from "../../components/upload/MachineTypeSelector";
import { UploadErrorMessages } from "../../components/upload/UploadErrorMessages";
import { getAcceptedFileType } from "../../utils/get-accepted-file-type";
import { useActiveLicence } from "../../hooks/useActiveLicence";
import {UploadDropZone} from "../../components/upload/UploadDropZone";
import usePreventUnload from "../../hooks/usePreventUnload";

export const SpectraQualityUpload: React.FC = () => {
  const [machineType, setMachineType] = useState<MachineType>();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState("");

  const navigate = useNavigate();
  const [hasActiveLicence] = useActiveLicence();
  usePreventUnload(isUploading);

  const acceptedFileType = getAcceptedFileType(machineType);

  const handleClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setMachineType(undefined);
    setSelectedFiles([]);
  };

  const handleSubmit = async (e: FormEvent) => {
    if (!machineType) return;
    setIsUploading(true);
    e.preventDefault();

    try {
      const result = await createSpectraQualityOrder(
        machineType,
        selectedFiles
      );
      logEvent(analytics, "user_submitted_spectra_quality_order", {
        filesCount: selectedFiles.length,
        machineType,
        selectedFiles,
      });
      console.log("order started ", result);
      setTimeout(() => {
        navigate(generatePath(SPECTRA_QUALITY_ORDER, { orderId: result.data }));
      }, 5000);
    } catch (e) {
      setIsUploading(false);
      setSubmitError(
        "We have encountered unexpected problems. Please contact us at contact.mabriteccentral@mabritec.com."
      );
      logEvent(analytics, "user_failed_to_submit", {
        error: e,
      });
      console.error(e);
    }
  };

  const disableSubmit =
      !selectedFiles
          .map((file) => accept(file, acceptedFileType))
          .every(Boolean) ||
      selectedFiles.length === 0 ||
      !machineType ||
      !hasActiveLicence ||
      selectedFiles.map((file) => file.size > 30 * 1024 * 1024).every(Boolean) ||
      isUploading ||
      selectedFiles.length > 500;

  return (
      <div className="container">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-8 mb-60">
            <div className="fw-bolder mb-20">
              E.coli (DSM 6897) spectra quality test
            </div>
            <div>
              Test your spetra quality (Sample preparation and machine tuning)
              with a a defined isolate of{" "}
            <a
              href="https://www.dsmz.de/collection/catalogue/details/culture/DSM-6897"
              target="_blank"
              rel="noreferrer"
            >
              E.coli (DSM 6897)
            </a>
            . The test is established for the smear method and the HCCA matrix
            with your standard machine settings.
          </div>
        </div>
        <div className="col-12 offset-lg-2 col-lg-8">
          <form
              encType="multipart/form-data"
              id="uploadForm"
              onSubmit={handleSubmit}
          >
            <MachineTypeSelector
                machineType={machineType}
                setMachineType={setMachineType}
                availableMachineTypes={[
                  "bruker",
                  "shimadzuAscii",
                  "vitekMSAscii",
                  "vitekMSMzml",
                ]}
            />
            <div className="mb-20">
              <label htmlFor="files" className="fw-bolder mb-20">
                02. Upload files:
              </label>

              <UploadDropZone selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles}
                              setMachineType={setMachineType}/>
            </div>

              <div className="d-flex gap-30 mb-20">
                <button
                    className="btn btn-outline-primary"
                    onClick={(e) => handleClear(e)}
                >
                  Clear
                </button>
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={disableSubmit}
                >
                  {isUploading ? (
                      <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                      />
                  ) : (
                      "Send"
                  )}
                </button>
              </div>
              <UploadErrorMessages
                  selectedFiles={selectedFiles}
                  hasActiveLicence={hasActiveLicence}
                  machineType={machineType}
                  acceptedFileType={acceptedFileType}
                  isUploading={isUploading}
                  submitError={submitError}
              />
          </form>
        </div>
        </div>
      </div>
);
};
