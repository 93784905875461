import React, { useEffect, useState } from "react";
import { MacoMatch, Spectra } from "../../typings";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, getMacoMatches } from "../../firebase";
import {generatePath, useParams, createSearchParams, Link, useLocation} from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { motion } from "motion/react";
import {MACO_MATCHES_DETAILS_ROUTE} from "../../Routes";
import {filterHIPeaks} from "../../utils/filter-hi-peaks";
import {useHasRole} from "../../hooks/useHasRole";

export const MacoMatches: React.FC = () => {
  const [spectra, setSpectra] = useState<Spectra | undefined>(undefined);
  const [isSpectraLoadig, setIsSpectraLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [matchData, setMatchData] = useState<MacoMatch[]>([]);
  const [minMatches, setMinMatches] = useState<number>(10);
  const [numberHIPeaks, setNumberHIPeaks] = useState<number>(50);
  const [getHIPeaks, setGetHIPeaks] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(40);
  const [ppm, setPpm] = useState<number>(800);
  const [database, setDatabase] = useState<string>("default");
  const [isMatchDataLoading, setIsMatchDataLoading] = useState<boolean>(false);
  const [user, loading] = useAuthState(auth);
  const { id } = useParams();
  const { hasRole: hasFungiRole } = useHasRole("fungi");
  const { state } = useLocation();

  // if state.database is "Fungi-SSP" set database to "Fungi-compare"
  useEffect(() => {
    if (state && state.database === "Fungi-SSP") {
      setDatabase("Fungi-compare");
    }
  }, [state]);

  // when spectra is set call handelGetMacoMatches() once
  useEffect(() => {
    if (spectra && matchData.length === 0) {
      handelGetMacoMatches();
    }
  }, [spectra]);

  // trigger handelGetMacoMatches() on if database changes
  useEffect(() => {
    if (spectra) {
      handelGetMacoMatches().then(console.log).catch(console.error);
    }
  }, [database, getHIPeaks]);

  useEffect(() => {
    if (loading) return;
    if (!user) return;
    if (id === undefined) return;

    const unsubscribe = onSnapshot(doc(db, "spectras", id), (doc) => {
      const data: Spectra = doc.data() as Spectra;
      setSpectra({ ...data, id: doc.id });
      setIsSpectraLoading(false);
    });
    return () => unsubscribe();
  }, [user, loading, id]);

  const getMatchDetailPath = (selectedProfile: string): string => {
    if(!spectra) return "";
    const path = generatePath(MACO_MATCHES_DETAILS_ROUTE, { id: spectra.id, selectedProfile: encodeURIComponent(selectedProfile) });
    const params = {
      database: encodeURIComponent(database),
      ppm: ppm.toString(),
      hi: numberHIPeaks.toString(),
      getHI: getHIPeaks.toString(),
    }

    return `${path}?${createSearchParams(params)}`;
  }

  // function get maco matches from cloud run
  const handelGetMacoMatches = async () => {
    if (!spectra) {
      console.error("No spectra loaded");
      setErrorMessage("No spectra loaded");
      return;
    }

    if (spectra.masses && spectra.masses.length === 0) {
      console.error("No masses in spectra");
      setErrorMessage("No masses in spectra");
      return;
    }

    setErrorMessage(undefined);
    setIsMatchDataLoading(true);
    setMatchData([]);

    let masses = Array.isArray(spectra.masses) ? spectra.masses: [];
    if(getHIPeaks) masses = filterHIPeaks(masses, numberHIPeaks);

    try {
      const { data } = await getMacoMatches({
        minMatches: minMatches,
        limit: limit,
        database: database,
        ppm: ppm,
        masses: masses,
      });
      setIsMatchDataLoading(false);

      if(data.length === 0) setErrorMessage("No matches found");
      setMatchData(data);
    } catch (error) {
      console.error("Failed to fetch maco matches", error);
      setErrorMessage("Failed to fetch matches");
      setIsMatchDataLoading(false);
    }
  };

  if (isSpectraLoadig) {
    return (
      <div className="container min-vh-100 align-items-center">
        <div
          className="position-absolute top-50 start-50 spinner-border text-primary"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (spectra === undefined) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> Spectra
            with not found.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 mb-20 mb-lg-20">
          <div>
            <span>
              Filename: <strong>{spectra?.file}</strong>
            </span>
          </div>
          <div>
            <span>
              Sample: <strong>{spectra?.sample}</strong>
            </span>
          </div>
          <div>
            <span>
              Datacount:{" "}
              <strong>{spectra?.masses && spectra.masses.length}</strong>
            </span>
          </div>
        </div>

        <form onSubmit={async (e) => {
          e.preventDefault();
          await handelGetMacoMatches();
        }}>
          <div className="col-12">
            <div className="row mb-20">
              <div className="col-4">
                <div className="input-group">
                  <span className="input-group-text">Min Matches</span>
                  <input
                      type="number"
                      className="form-control"
                      min={1}
                      value={minMatches}
                      onChange={(e) => setMinMatches(parseInt(e.target.value))}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="input-group">
                  <span className="input-group-text">Limit</span>
                  <input
                      type="number"
                      className="form-control"
                      min={1}
                      value={limit}
                      onChange={(e) => setLimit(parseInt(e.target.value))}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="input-group">
                  <span className="input-group-text">PPM</span>
                  <input
                      type="number"
                      className="form-control"
                      min={0}
                      value={ppm}
                      step={50}
                      onChange={(e) => setPpm(parseInt(e.target.value))}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-20">
              <div className="col-4">
                <div className="input-group">
                  <span className="input-group-text">HI</span>
                  <input
                      type="number"
                      className="form-control"
                      min={0}
                      value={numberHIPeaks}
                      step={10}
                      onChange={(e) => setNumberHIPeaks(parseInt(e.target.value))}
                  />
                  <div className="input-group-text">
                    <input
                        type="checkbox"
                        checked={getHIPeaks}
                        onChange={(e) => setGetHIPeaks(e.target.checked)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-20">
              <div className="col-12">
                <div className="input-group">
                  <span className="input-group-text">Database</span>
                  <select
                      className="form-select text-truncate"
                      id="database"
                      name="database"
                      aria-label="Select database"
                      value={database}
                      onChange={(e) => setDatabase(e.target.value)}
                  >
                    <option value="default">All Bacteria</option>
                    <option value="Acinetobacter-baumannii-complex">
                      Acinetobacter baumannii complex
                    </option>
                    <option value="Bacillus-cereus-group">
                      Bacillus cereus group
                    </option>
                    <option value="Bordetella-pertussis-parapertussis-bronchiseptica">
                      Bordetella pertussis / parapertussis / bronchiseptica
                    </option>
                    <option value="Burkholderia-cepacia-complex">
                      Burkholderia cepacia complex
                    </option>
                    <option value="Escherichia-Shigella">
                      Escherichia / Shigella
                    </option>
                    <option value="Enterobacter-cloacae-complex">
                      Enterobacter cloacae complex
                    </option>
                    <option value="Klebsiella-Raoultella">
                      Klebsiella / Raoultella
                    </option>
                    <option value="Listeria">
                      Listeria "sensu stricto" clade
                    </option>
                    <option value="Salmonella">Salmonella</option>
                    <option value="Staphylococcus-aureus-complex">
                      Staphylococcus aureus complex
                    </option>
                    <option value="Streptococcus-mitis-group">
                      Streptococcus mitis group
                    </option>
                    {hasFungiRole && (
                        <option value="Fungi-compare">Fungi</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mb-20">
            <button
                type="submit"
                className="btn btn-primary mb-10"
                disabled={isMatchDataLoading}
            >
              {isMatchDataLoading ? (
                  <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                  />
              ) : (
                  "Compare"
              )}
            </button>
          </div>
        </form>

        <div className="col-12">
          <div className="d-none d-lg-block overview-results__header bg-primary py-20 px-30">
            <div className="row">
              <div className="col-4">
                <strong className="text-black">Profile</strong>
              </div>
              <div className="col-2">
                <strong className="text-black">Family</strong>
              </div>
              <div className="col-2">
                <strong className="text-black">Genus</strong>
              </div>
              <div className="col-3">
                <strong className="text-black">Species</strong>
              </div>
              <div className="col-1">
                <strong className="text-black">Matches</strong>
              </div>
            </div>
          </div>
        </div>


        {errorMessage && (<div className="col-12 text-center mt-10"><FontAwesomeIcon
            icon={faExclamationTriangle as IconProp}/> {errorMessage}</div>)}

        {matchData.map(({profile, family, genus, species_plus, matches}, index) => (
            <motion.div
                initial={{
                  opacity: 0,
                  y: 25
                }}
                animate={{
                  opacity: 1,
                  y: 0
                }}
                transition={{ delay: index * 0.08}}
                className="col-12" key={index}>
              <Link to={getMatchDetailPath(profile)} target="_blank">
                <div className="detail-results__wrapper bg-white">
                  <div className="row gy-20">
                    <div className="col-12 col-sm-6 col-lg-4">
                <span className="text-break">
                  <span className="d-block d-lg-none fw-bold">Profile:</span>
                  {profile}
                </span>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-2">
                <span className="text-break">
                  <span className="d-block d-lg-none fw-bold">Family</span>
                  {family}
                </span>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-2">
                <span className="text-break">
                  <span className="d-block d-lg-none fw-bold">Genus:</span>
                  {genus}
                </span>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <div>
                        <span className="d-block d-lg-none fw-bold">Species:</span>
                        <div className="fw-bold">{species_plus}</div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-1">
                      <div>
                        <span className="d-block d-lg-none fw-bold">Qualified score:</span>
                        <div className="fw-bold">{matches}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </motion.div>
        ))}
      </div>
    </div>
  );
};
