import React, {useEffect, useMemo, useRef, useState} from "react";
import * as d3 from "d3";
import { zoom } from "d3-zoom";
import {
  ZoomTransform,
  scaleLinear,
  select,
  axisLeft,
  axisBottom,
  Selection,
} from "d3";
import useResizeObserver from "../hooks/useRezizeObserver";
import { ppmToHexColor } from "../utils/ppm-to-hex-color";
import PPPMErrorLegend from "./PPMErrorLegend";

type Props = {
  masses: { int: number; mz: number }[];
  matchedProfile?: {
    mw: number;
    subunit: string;
    matched?: boolean;
    intensity?: number;
    mz_error?: number;
  }[];
};

const MARGIN = { top: 10, right: 30, bottom: 30, left: 60 };

const getEmptyzones = (masses: { int: number; mz: number }[]) => {
  let emptyzones: { start: number; end: number }[] = [];
  // add last peak to get emptyzone to 20'000
  const newMasses = [...masses, { mz: 20000, int: 0 }];

  newMasses
    .sort((a, b) => a.mz - b.mz)
    .forEach(({ mz }, i) => {
      if (
        i + 1 !== newMasses.length &&
        Math.abs(mz - newMasses[i + 1].mz) > 800
      ) {
        emptyzones.push({ start: mz, end: newMasses[i + 1].mz });
      }
    });

  return emptyzones;
};

const getHighestInt = (listInt: number[]) =>
  Math.max(...listInt);

export const SpectrumPlot: React.FC<Props> = (props) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useResizeObserver(wrapperRef);
  const [currentZoomState, setCurrentZoomState] = useState<ZoomTransform>();

  const { width = 0, height = 0 } = dimensions! || { width: 0, height: 0 }; // || wrapperRef !== null && wrapperRef.current!.getBoundingClientRect();

  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  const highestSubunitInt = useMemo(() => getHighestInt(props.matchedProfile?.map(({intensity}) => intensity ?? 0) || [0]), [props.matchedProfile]);
  const highestInt = useMemo(() => getHighestInt(props.masses.map(({int}) => int)), [props.masses]);
  const subunitIntFactor = highestInt / highestSubunitInt;
  const matchedProfile = useMemo(() => props.matchedProfile?.map(({intensity, ...rest}) => ({...rest, intensity: intensity ? intensity * subunitIntFactor : 0})), [props.matchedProfile, subunitIntFactor]);
  const emptyzonesData = useMemo(() => getEmptyzones(props.masses), [props.masses]);

  // memoized ppmToHexColor function

    const ppmToHexColorMemoized = useMemo(() => ppmToHexColor, []);

    // will be called initially and on every data change
  useEffect(() => {
    if (!svgRef.current) return;
    const svg = select(svgRef.current);
    const svgContent = svg.select(".content");

    // scales + line generator
    const xScale = scaleLinear().domain([4000, 20000]).range([0, boundsWidth]);

    if (currentZoomState) {
      const newXScale = currentZoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }

    const yScaleMax = highestInt;

    const yScale = scaleLinear()
      .domain([0, yScaleMax])
      .range([boundsHeight, 0]);

    const visiblePeaks = props.masses.filter(
        ({ mz }) => mz >= xScale.domain()[0] && mz <= xScale.domain()[1]
    );

    const visibleSubunits = matchedProfile?.filter(
        ({ mw }) => mw >= xScale.domain()[0] && mw <= xScale.domain()[1]
    ) || [];


    // Create or select groups for emptyzones, subunits and peaks
    const emptyzonesGroup: d3.Selection<SVGGElement, unknown, null, undefined> = svgContent
        .select<SVGGElement>("g.emptyzonesGroup")
        .empty()
        ? svgContent.append<SVGGElement>("g").attr("class", "emptyzonesGroup")
        : svgContent.select<SVGGElement>("g.emptyzonesGroup");

    const subunitsGroup: d3.Selection<SVGGElement, unknown, null, undefined> = svgContent
        .select<SVGGElement>("g.subunitsGroup")
        .empty()
        ? svgContent.append<SVGGElement>("g").attr("class", "subunitsGroup")
        : svgContent.select<SVGGElement>("g.subunitsGroup");


    const peaksGroup: d3.Selection<SVGGElement, unknown, null, undefined> =
        svgContent.select<SVGGElement>("g.peaksGroup").empty()
            ? svgContent.append<SVGGElement>("g").attr("class", "peaksGroup")
            : svgContent.select<SVGGElement>("g.peaksGroup");

    // spectrum peaks
    const emptyzones = emptyzonesGroup
        .selectAll<SVGLineElement, { start: number; end: number }>(".emptyzones")
        .data(emptyzonesData);

    emptyzones.exit().remove();

    // emptyzones
    emptyzones
        .enter()
        .append("line")
        .attr("class", "emptyzones")
        .merge(emptyzones)
        .attr("x1", ({ start }) => Math.round(xScale(start + 5)))
        .attr("x2", ({ end }) => Math.round(xScale(end - 5)))
        .attr("y1", yScale(0))
        .attr("y2", yScale(0))
        .attr("stroke", "red")
        .attr("stroke-width", 4)
        .attr("data-start", ({ start }) => start)
        .attr("data-end", ({ end }) => end)
        .on("mouseover", (event) => {
          const tooltipDiv = tooltipRef.current;
          select(event.currentTarget).attr("stroke", "#d3df00");
          if (tooltipDiv)
            return d3
                .select(tooltipDiv)
                .style("opacity", "1")
                .style("visibility", "visible");
        })
        .on("mousemove", (event) => {
          const tooltipDiv = tooltipRef.current;
          if (tooltipDiv) {
            const target = event.target as SVGLineElement;
            return d3
                .select(tooltipDiv)
                .style("top", event.pageY - 30 + "px")
                .style("left", event.pageX + 10 + "px")
                .html(
                    `emptyzone from ${Number(target.dataset.start).toLocaleString(
                        "de-CH"
                    )} m/z to ${Number(target.dataset.end).toLocaleString(
                        "de-CH"
                    )} m/z`
                );
          }
        })
        .on("mouseleave", (event) => {
          const tooltipDiv = tooltipRef.current;
          d3.select(event.currentTarget).attr("stroke", "red");
          if (tooltipDiv)
            return d3
                .select(tooltipDiv)
                .style("opacity", "0")
                .style("visibility", "hidden");
        });



    // subunits
    const subunits = subunitsGroup
        .selectAll<SVGLineElement, { mw: number; subunit: string; intensity: number, mz_error: number }>(".subunits")
        .data(visibleSubunits.filter(({ mw }) => mw < 20000));

    subunits.exit().remove();

    subunits
        .enter()
        .append("line")
        .attr("class", "subunits")
        .merge(subunits)
        .attr("stroke", ({ matched, mz_error = 0 }) => (matched ? ppmToHexColorMemoized(mz_error) : "grey"))
        .attr("stroke-width", 8)
        .attr("x1", ({ mw }) => Math.round(xScale(mw)))
        .attr("x2", ({ mw }) => Math.round(xScale(mw)))
        .attr("y1", ({ intensity }) => intensity ? Math.round(yScale(intensity)) : yScale(yScaleMax))
        .attr("y2", yScale(0))
        .attr("opacity", ({ matched }) => (matched ? 0.6 : 0.2))
        .attr("data-subunit", ({ subunit }) => subunit)
        .attr("data-mz_error", ({ mz_error }) => mz_error || 0)
        .on("mouseover", (event) => {
          const tooltipDiv = tooltipRef.current;
          select(event.currentTarget)
              .attr("stroke", "#DC3546")
              .attr("opacity", 1)
              .raise();
          if (tooltipDiv)
            return d3
                .select(tooltipDiv)
                .style("opacity", "1")
                .style("visibility", "visible");
        })
        .on("mousemove", (event) => {
          const tooltipDiv = tooltipRef.current;
          if (tooltipDiv) {
            const target = event.target as SVGLineElement;
            const mz_error = Number(target.dataset.mz_error);

            return d3
                .select(tooltipDiv)
                .style("top", event.pageY - 30 + "px")
                .style("left", event.pageX + 10 + "px")
                .html(`${target.dataset.subunit} ${mz_error !== 0 ? `| ${Math.round(Number(target.dataset.mz_error))} ppm error` : ""}`);
          }
        })
        .on("mouseleave", (event) => {
          const tooltipDiv = tooltipRef.current;
          d3.select<SVGElement, {
            mw: number;
            subunit: string;
            matched?: boolean;
            mz_error?: number;
          }>(event.currentTarget)
              .attr("stroke", ({ matched, mz_error = 0 }) => (matched ? ppmToHexColorMemoized(mz_error) : "grey"))
              .attr("opacity", ({ matched }) => (matched ? 0.6 : 0.2));
          if (tooltipDiv)
            return d3
                .select(tooltipDiv)
                .style("opacity", "0")
                .style("visibility", "hidden");
        });

    // spectrum peaks
    const peaks = peaksGroup
        .selectAll<SVGLineElement, { mz: number; int: number }>(".allPeaks")
        .data(visiblePeaks.filter(({ mz }) => mz > 4000), (d) => d.mz);

    peaks.exit().remove();

    peaks
        .enter()
        .append("line")
        .attr("class", "allPeaks")
        .merge(peaks)
        .attr("x1", ({ mz }) => Math.round(xScale(mz)))
        .attr("x2", ({ mz }) => Math.round(xScale(mz)))
        .attr("y1", ({ int }) => Math.round(yScale(int)))
        .attr("y2", yScale(0))
        .attr("stroke", "#282c32")
        .attr("stroke-width", 3)
        .attr("data-mz", ({ mz }) => Math.round(mz * 10) / 10)
        .attr("data-int", ({ int }) => Math.round(int))
        .on("mouseover", (event) => {
          const tooltipDiv = tooltipRef.current;
          select(event.currentTarget).attr("stroke", "#DC3546");
          event.currentTarget.parentNode.appendChild(event.currentTarget); // Bring to front
          if (tooltipDiv)
            d3
                .select(tooltipDiv)
                .style("opacity", "1")
                .style("visibility", "visible");
        })
        .on("mousemove", (event) => {
          const tooltipDiv = tooltipRef.current;
          if (tooltipDiv) {
            const target = event.target as SVGLineElement;
            return d3
                .select(tooltipDiv)
                .style("top", event.pageY - 30 + "px")
                .style("left", event.pageX + 10 + "px")
                .html(
                    `m/z: ${Number(target.dataset.mz).toLocaleString(
                        "de-CH"
                    )} | intensity: ${Number(target.dataset.int).toLocaleString(
                        "de-CH"
                    )}`
                );
          }
        })
        .on("mouseleave", (event) => {
          const tooltipDiv = tooltipRef.current;
          d3.select(event.currentTarget).attr("stroke", "#282c32");
          if (tooltipDiv)
            return d3
                .select(tooltipDiv)
                .style("opacity", "0")
                .style("visibility", "hidden");
        })


    // axes
    const xAxis = axisBottom(xScale);

    svg
      .select<SVGSVGElement>(".x-axis")
      .attr("transform", `translate(0, ${boundsHeight})`)
      .call(xAxis);

    const yAxis = axisLeft(yScale);
    svg.select<SVGSVGElement>(".y-axis").call(yAxis);

    // zoom
    const zoomBehavior = zoom()
      .scaleExtent([1, 40])
      .translateExtent([
        [0, 0],
        [width, height],
      ])
      .on("zoom", (event: { transform: any; currentTarget: any; }) => {
        const zoomState = event.transform;
        setCurrentZoomState(zoomState);

        // remove tooltip
        const tooltipDiv = tooltipRef.current;
        d3.select(event.currentTarget).attr("stroke", "#282c32");
        if (tooltipDiv)
          return d3
            .select(tooltipDiv)
            .style("opacity", "0")
            .style("visibility", "hidden");
      });

    // todo clen up?
    svg.call((selection) =>
      zoomBehavior(
        selection as unknown as Selection<Element, unknown, any, any>
      )
    );
  }, [
    currentZoomState,
    props,
    dimensions,
    boundsWidth,
    boundsHeight,
    height,
    width,
      emptyzonesData,
      highestInt,
      matchedProfile,
      ppmToHexColorMemoized
  ]);

  return (
    <div ref={wrapperRef} style={{ marginBottom: "2rem", height: "400px" }}>
      <div
        className="tooltip"
        ref={tooltipRef}
        style={{ background: "white", padding: "2px 5px" }}
      />
      <svg
        ref={svgRef}
        width={width}
        height={height}
        style={{ display: "inline-block" }}
      >
        <defs>
          <clipPath id="spectrum-plot">
            <rect x="0" y="0" width="100%" height="100%" />
          </clipPath>
        </defs>
        <g
          className="content"
          clipPath={`url(#spectrum-plot`}
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        />
        <g
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        >
          <g className="x-axis" />
          <g className="y-axis" />
        </g>
      </svg>
        <PPPMErrorLegend />

    </div>
  );
};
