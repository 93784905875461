import React, { ReactNode } from 'react';
import {useHasRole} from "../../hooks/useHasRole";


type RoleProtectedWrapperProps = {
    children: ReactNode;
    role: string;
}

export const RoleProtectedWrapper: React.FC<RoleProtectedWrapperProps> = ({ role, children }) => {
    const { loading, hasRole } = useHasRole(role);

    if (loading || !hasRole) return null;

    return <>{children}</>
}